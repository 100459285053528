import React from "react";
import Images from "../../constants/Images";

import "./LandingPage.css";

export default function LandingPage() {
  function downloadAppPress() {}

  return (
    <div className="landingPage-container">
      <div className="landingPage-subContainer">
        <div className="landingPage-mainText">Relieve Your Anxiety</div>
        <div className="landingPage-subText">
          Calm down with interactive, visual &amp; haptic experiences
        </div>
        <div className="landingPage-downloadButtonsContainer">
          <a
            className="landingPage-downloadButton"
            href="https://apps.apple.com/us/app/stress-ball-anxiety-relief/id1546704945"
          >
            <img
              src={Images.downloadButtonApple}
              className="landingPage-downloadImage"
            />
          </a>
          <a
            className="landingPage-downloadButton"
            href="https://play.google.com/store/apps/details?id=com.kmoney.soothe&pli=1"
          >
            <img
              src={Images.downloadButtonGoogle}
              className="landingPage-downloadImage"
            />
          </a>
        </div>
      </div>
      <img
        src={Images.appScreenshots}
        className="landingPage-appScreenshotsImage"
      />
    </div>
  );
}
